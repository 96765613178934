import * as React from "react";
import AboutSecound from "../components/AboutSecound/AboutSecound";
import AboutThird from "../components/AboutThird/AboutThird";
import IndexAboutTop from "../components/IndexAboutTop/IndexAboutTop";
import Intro from "../components/Intro/Intro";
import Seo from "../components/seo";
import ToPage from "../components/toPage/toPage";
import useWindowSize from "../components/useWindowSize/useWindowSize";
import Layout from "../layout";

const IndexPage = () => {
  const { size } = useWindowSize();

  return (
    <Layout>
      <Intro />
      <IndexAboutTop />
      <AboutSecound />
      <AboutThird />
      <ToPage />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <Seo
    description="小倉たんはらみは国産牛100%にこだわっています,厳選された牛タン・ハラミ・サガリ・ホルモン肉を
いろりの炭火で焼くといった、当店独自のスタイルで提供します"
  />
);

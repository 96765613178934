// extracted by mini-css-extract-plugin
export var about = "AboutThird-module--about--64ed6";
export var content = "AboutThird-module--content--bba7a";
export var description = "AboutThird-module--description--53e66";
export var heading = "AboutThird-module--heading--8e8c9";
export var image = "AboutThird-module--image--64074";
export var imageWrapper = "AboutThird-module--imageWrapper--9fc58";
export var no = "AboutThird-module--no--db32d";
export var secondImage = "AboutThird-module--secondImage--4624a";
export var secondText = "AboutThird-module--secondText--a1dec";
export var textWrapper = "AboutThird-module--textWrapper--28fec";
export var title = "AboutThird-module--title--a8707";
export var title_no = "AboutThird-module--title_no--3968f";
import * as React from 'react';
import * as styles from './toPage.module.css';
import { Link } from 'gatsby';

const pages1 = [
  {
    path: '/about',
    image: 'images/index/touten.jpg',
    alt: 'about',
    text: '当店について',
  },
  {
    path: 'https://niku-nagaoka.com/',
    image: 'images/index/daihyou.jpg',
    alt: 'to NikunoNagaoka',
    text: '肉のながおか',
  },
];

const pages2 = [
  {
    path: '/info',
    image: 'images/index/info.jpg',
    alt: 'info',
    text: '営業時間\nアクセス',
  },
  {
    path: '/menu',
    image: 'images/index/yakiniku_menu.jpg',
    alt: 'menu',
    text: 'メニュー',
  },
];

const ToPage = () => {
  return (
    <div className={styles.toPage}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          {pages1.map((page, index) => (
            <Link 
              key={page.path} 
              to={page.path} 
              className={styles.imageWrapper}
              // 2番目の要素（index === 1）の場合のみ target="_blank" を追加
              target={index === 1 ? '_blank' : undefined} 
              rel={index === 1 ? 'noopener noreferrer' : undefined}
              >
              <img src={page.image} alt={page.alt} className={styles.image} />
              <div className={styles.linkText}>{page.text}</div>
            </Link>
          ))}
        </div>
        <div className={styles.imageContainer}>
          {pages2.map((page) => (
            <Link key={page.path} to={page.path} className={styles.imageWrapper}>
              <img src={page.image} alt={page.alt} className={styles.image} />
              <div className={styles.linkText}>
                {page.text.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    {i !== page.text.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToPage;

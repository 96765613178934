import * as React from 'react';
import * as styles from './Intro.module.css';

const Intro = () => {


    return (
        <div className={styles.intro}>
            
          <div className={styles.content}>
              <div className={styles.textWrapper}>
              <h2 className={styles.heading}>小倉たんはらみは<br/>国産牛100%に<br className={styles.no}/>こだわっています</h2>
                  <p className={styles.description}>
                    いろりを囲んだ空間、 非日常な空気があなたを包みます<br/>
                    炭火で調理された料理の数々、その美味しさに思わずくつろいでしまうでしょう<br/><br/>

                    大切な人との特別な日、そして何気ない日常の中のちょっとした贅沢として<br/>
                    国産タン・ハラミ・サガリ・ホルモンの極上の味わいが、あなたの大切な時間をより一層輝かせます<br/><br/>

                    私たちは、お客様に最高のひと時をお過ごしいただくために、100%国産牛にこだわり続けます<br/>
                  </p>
              </div>
              <div className={styles.imageContainer}>
                <div className={styles.imageWrapper}>
                  <img src="images/index/nama2.jpg" alt="niku" className={styles.image} />
                </div>
                <div className={styles.imageWrapper}>
                  <img src="images/index/girls_intro_2.jpg" alt="irori" className={styles.image} />
                </div>
              </div>
          </div>
        </div>
  );
};

export default Intro;






// extracted by mini-css-extract-plugin
export var about = "IndexAboutTop-module--about--1137a";
export var content = "IndexAboutTop-module--content--cc54d";
export var description = "IndexAboutTop-module--description--69be5";
export var heading = "IndexAboutTop-module--heading--30561";
export var image = "IndexAboutTop-module--image--e3f42";
export var imageWrapper = "IndexAboutTop-module--imageWrapper--e8114";
export var no = "IndexAboutTop-module--no--fff38";
export var secondImage = "IndexAboutTop-module--secondImage--3152b";
export var secondText = "IndexAboutTop-module--secondText--1a68c";
export var textWrapper = "IndexAboutTop-module--textWrapper--18932";
export var title = "IndexAboutTop-module--title--9bcc1";
export var title_no = "IndexAboutTop-module--title_no--d25f4";
// extracted by mini-css-extract-plugin
export var about = "AboutSecound-module--about--061a5";
export var content = "AboutSecound-module--content--cf311";
export var description = "AboutSecound-module--description--da097";
export var heading = "AboutSecound-module--heading--525d8";
export var image = "AboutSecound-module--image--0c46c";
export var imageWrapper = "AboutSecound-module--imageWrapper--7a4bc";
export var no = "AboutSecound-module--no--3da64";
export var secondImage = "AboutSecound-module--secondImage--411a4";
export var secondText = "AboutSecound-module--secondText--8d8fa";
export var textWrapper = "AboutSecound-module--textWrapper--9cca1";
export var title = "AboutSecound-module--title--85990";
export var title_no = "AboutSecound-module--title_no--a46ad";
import * as React from 'react';
import * as styles from './AboutThird.module.css';

const AboutThird = () => {
  return (
    <div className={styles.about}>
        <h2 className={styles.heading}>小倉たんはらみで<br/>和と食のハーモニーを</h2>        

        <div className={styles.content}>
            <div className={styles.imageWrapper}>
              <img src="/images/index/wine_about_3.jpg" alt="about_3" className={styles.image} />
            </div>
            <div className={styles.textWrapper}>
              <h2 className={styles.title}>いろりと炭火とお料理</h2>
              <p className={styles.description}>
                小倉タンハラミでは、国産タン・ハラミ・サガリ・ホルモンを炭火で楽しむことができます<br/>
                シンプルでありながら、肉本来の甘み・旨みを最大限に引き出します<br/><br/>

                料理に合わせたお飲み物も日本各地から取り寄せた地酒や焼酎、ワインなど、豊富なラインナップをご用意しています<br/><br/>

                いろりを囲んで、大切な人と語らいながら過ごす時間<br className={styles.no}/>
                炭火の温かみと美味しい料理に包まれて、心までほっこりと和らぐひと時<br className={styles.no}/>
                そんな特別な空間を、私たちはお客様にお届けします

              </p>
            </div>

            
        </div>

            

    </div>
  );
};

export default AboutThird;

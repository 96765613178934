import * as React from 'react';
import * as styles from './AboutSecound.module.css';
import TitleUnderline from '../title_underline/TitleUnderline';

const AboutSecound = () => {
  return (
    <div className={styles.about}>
        <TitleUnderline title="こだわりの空間" color="#fff" underlineColor="#fff" />

        <div className={styles.content}>
            
            <div className={`${styles.imageWrapper} ${styles.secondImage}`}>
              <img src="/images/index/about_2.jpg" alt="about_2" className={styles.image} />
            </div>
            <div className={`${styles.textWrapper} ${styles.secondText}`}>
              <h2 className={styles.title}>いろりのある空間で</h2>
              <p className={styles.description}>
                日本人の心に残る「いろり」を囲む<br/>
                そんな空間で堪能する国産タン・ハラミ・サガリ・ホルモンは、<br className={styles.no}/>
                いつもより暖かみを感じるでしょう<br/><br/>

                炭火の温かな光に照らされた食材は、本来の味わいを<br className={styles.no}/>
                存分に引き出します<br/>
                いろりを囲みながら、ゆっくりと時間をかけて味わう国産牛は<br className={styles.no}/>
                素朴でありながら、どこか懐かしさを感じさせる味わいです<br/><br/>

                私たちは、この日本の伝統的な<br className={styles.no}/>
                炭火焼といろりの文化を大切にしています<br className={styles.no}/>
                北九州小倉にある、ワインと和牛ステーキの<br className={styles.no}/>
                お店「肉のながおか」代表が厳選した<br className={styles.no}/>
                国産タン・ハラミ・サガリ・ホルモンを<br className={styles.no}/>
                愉しんでみてはいかがでしょうか<br/>
                
              </p>
            </div>
            
            
        </div>

    </div>
  );
};

export default AboutSecound;
